<template>
    <div
        :class="[
            isLoggedIn ? 'logged-in-access' : 'guest-access',
            {
                'overflow-hidden w-full': !scrollingIsEnabled,
                'pt-20 md:pt-23': !isLoggedIn && !topBarIsInset,
            },
            'flex flex-col w-auto min-h-screen-visible',
            bgColor ? `bg-${bgColor}` : undefined,
        ]"
        data-testid="the-layout"
    >
        <div class="flex flex-row min-h-screen-visible">
            <TheSidebar
                v-if="isLoggedIn"
                large-screen-animation
                @finished="(rendered) => (sidebarRendered = rendered)"
            />

            <div
                id="main-container"
                class="flex flex-col flex-grow min-w-0"
            >
                <TheTopBar />

                <div
                    id="content-container"
                    class="flex flex-col w-full flex-grow items-center"
                >
                    <slot name="breadcrumbs"></slot>
                    <portal-target name="breadcrumbs" />

                    <div class="w-full">
                        <slot name="header"></slot>
                        <portal-target name="header" />
                    </div>
                    <div class="flex-grow w-full">
                        <slot>
                            <router-view v-if="!$slots.default" />
                        </slot>
                        <TheGridOverlay v-if="debugMode" />
                    </div>
                </div>

                <TheFooter v-if="!isLoggedIn && footerIsEnabled" />

                <div
                    v-if="shouldShowAd && isLoggedIn"
                    class="bottom-fixed z-10"
                >
                    <Xandr
                        ref="xandr-bottom-fixed-ad"
                        class="!flex-row"
                        xandr-wrapper="bottom_fixed_bannerWrapper"
                        xandr-slot="bottom_fixed_banner"
                        is-bottom-fixed-ad
                    >
                        <template #xandr-ad-footer="slotPops">
                            <div
                                v-if="slotPops.hasAd"
                                class="flex flex-col border bg-white items-start"
                            >
                                <BtnMenu
                                    class="border-b"
                                    variant="filled"
                                    size="xxs"
                                    left-icon="more-vertical"
                                    :options="options"
                                    menu-placement="bottom-end"
                                />
                                <Btn
                                    color="black-100"
                                    left-icon="close"
                                    size="xxs"
                                    @click="closeAd"
                                >
                                </Btn>
                            </div>
                        </template>
                    </Xandr>
                </div>
            </div>

            <NavigationDrawer
                v-if="isLoggedIn && screen.maxMD"
                :open="$store.getters['ui/isNotificationsDrawerOpen']"
                side="right"
                :drawer-width="screen.maxSM ? '100%' : '50%'"
                class="pt-16 md:pt-18"
                container-class="lg:hidden"
                with-backdrop
                fixed
                @close="$store.dispatch('ui/setNotificationsDrawerOpen', false)"
            >
                <div
                    v-if="unreadNotificationsCount"
                    class="flex justify-end m-1"
                >
                    <Btn
                        variant="text"
                        :label="$t('common:markAllNotifications')"
                        color="primary"
                        size="xxs"
                        @click="readAllHeaderNotification"
                    />
                </div>
                <ul
                    v-if="notifications.length"
                    class="h-full overflow-auto scrolling-auto"
                >
                    <NotificationCard
                        v-for="notification in notifications"
                        :key="notification.id"
                        :notification="notification"
                    ></NotificationCard>
                </ul>
            </NavigationDrawer>
        </div>

        <LayoutCallouts v-if="calloutsEnabled && hasUnreadCallouts && sidebarRendered" />

        <TheAuthOverlay v-if="activeAuthOverlay" />

        <portal-target
            name="overlays"
            multiple
        ></portal-target>

        <transition
            v-if="!consentManagement"
            name="slide-up"
        >
            <TheCookieBanner v-if="!acceptedCookiesUsage" />
        </transition>

        <FlashMessages />

        <Transition name="fade">
            <XandrFullScreenAdWrapper
                v-if="isLoggedIn && isXandrAdLibLoaded"
                :xandr-wrapper="`${xandrFullScreenType}Wrapper`"
                :xandr-slot="xandrFullScreenType"
                class="fixed"
                @xandr-fullscreen-ad-viewed="storeFullScreenCookie"
            />
        </Transition>

        <GlobalEvents
            v-if="globalListenersEnabled"
            @click.capture="globalClickEvent"
        ></GlobalEvents>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import { useCookies } from '@vueuse/integrations/useCookies';
import TheTopBar from '@components/TheTopBar';
import NavigationDrawer from '@components/NavigationDrawer';
import NotificationCard from '@components/NotificationCard';
import TheFooter from '@components/TheFooter';
import TheCookieBanner from '@components/TheCookieBanner';
import FlashMessages from '@components/FlashMessages';
import { detectAllAdblocker } from '@helpers/adBlockers';
import hashScrollMixin from '@/mixins/hashScrollMixin';
import { postUserBlocksAd } from '@/api/backend/ad';
import { Xandr, XandrFullScreenAdWrapper } from '@components/Xandr';
import { CALLOUTS } from '@/utils/calloutUtils';
import { checkAdCookie, storeXandrFullScreenAdViewed } from '@/plugins/cookie';
import LayoutMixin from '@mixins/layoutMixin';
import { getResizedAds } from '@/modules/xandr';
import { featureIsEnabled } from '@/modules/features';
import { Cookie } from '@/utils/cookieUtils';
import { BREAKPOINT } from '@/utils/uiUtils';
import BtnMenu from '@components/BtnMenu/BtnMenu.vue';
import Btn from '@components/Btn/Btn.vue';

export default {
    name: 'TheLayout',
    components: {
        Btn,
        BtnMenu,
        XandrFullScreenAdWrapper,
        FlashMessages,
        TheCookieBanner,
        TheTopBar,
        TheFooter,
        Xandr,
        NavigationDrawer,
        NotificationCard,
        TheSidebar: defineAsyncComponent(() => import('@components/TheSidebar')),
        TheAuthOverlay: defineAsyncComponent(() => import('@components/TheAuthOverlay')),
        TheGridOverlay: defineAsyncComponent(() => import('@components/TheGridOverlay')),
        LayoutCallouts: defineAsyncComponent(() => import('./LayoutCallouts.vue')),
    },
    extends: LayoutMixin,
    mixins: [hashScrollMixin],
    props: {
        bgColor: {
            type: String,
            default: undefined,
        },
    },
    setup() {
        return {
            cookies: useCookies(),
        };
    },
    data() {
        return {
            // eslint-disable-next-line no-undef
            consentManagement: sdWindow.consentManagement,
            sidebarRendered: false,
            layoutMounted: false,
            debugMode: import.meta.env.MIX_DEBUG === 'true',
        };
    },
    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapGetters('ui', [
            'acceptedCookiesUsage',
            'activeAuthOverlay',
            'scrollingIsEnabled',
            'topBarIsInset',
            'globalListenersEnabled',
            'footerIsEnabled',
            'screen',
            'screenSize',
        ]),
        ...mapGetters('ad', ['isBottomFixedAdAllowed', 'hasAdBlocker']),
        ...mapGetters('xandr', ['isXandrAdLibLoaded']),
        ...mapGetters('dsa', ['dsaReportData']),
        ...mapGetters('user', ['user']),
        ...mapGetters({
            notifications: 'notifications/notifications',
            unreadNotificationsCount: 'notifications/unreadNotificationsCount',
        }),

        shouldShowAd() {
            return !checkAdCookie('xandr-bottom-fixed') && this.screen.maxSM && this.isBottomFixedAdAllowed;
        },
        calloutsEnabled() {
            return featureIsEnabled('callouts');
        },
        hasUnreadCallouts() {
            return [
                CALLOUTS.newsfeed,
                CALLOUTS.courses,
                CALLOUTS.groups,
                CALLOUTS.profile,
                CALLOUTS.ceDashboard,
                CALLOUTS.newsfeedCareerCornerExistingUser,
                CALLOUTS.newsfeedCareerCornerOnBoarding,
            ].some((id) => this.$store.getters['callout/unreadCallouts'].includes(id));
        },
        xandrFullScreenType() {
            return window.innerWidth < BREAKPOINT.MD ? 'fullpage_video_mew' : 'fullpage_video';
        },
        options() {
            const options = [
                {
                    label: this.$t('dsa:dsa-info-text'),
                    icon: 'info',
                    onClick: this.openAdDsaInfo,
                    authorizedAction: true,
                    class: 'black-100',
                },
                {
                    label: this.$t('dsa:menu.report'),
                    icon: 'flag',
                    onClick: this.reportAd,
                    authorizedAction: !this.dsaReportData?.bottom_fixed_banner?.isReported,
                    class: 'black-100',
                },
            ];

            return options.filter((items) => items.authorizedAction);
        },
    },
    watch: {
        screenSize: {
            handler(to, from) {
                if (to !== from && window.adSSetup) {
                    getResizedAds();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.isLoggedIn) {
            // checking for ad blocking capabilities
            detectAllAdblocker()
                .then(() => {
                    const hadAdBlockedCookie = this.cookies.get(Cookie.AD_BLOCK_USED); // can be undefined, "0" or "1"
                    if (hadAdBlockedCookie === undefined || this.hasAdBlocker !== !!parseInt(hadAdBlockedCookie, 10)) {
                        // if we notice a change in blocking capability, we notify our backend.
                        // otherwise we don't need to and can safe on resources
                        const today = new Date();
                        const expiry = today.setDate(today.getDate() + 100);
                        this.cookies.set(Cookie.AD_BLOCK_USED, this.hasAdBlocker ? 1 : 0, {
                            expires: new Date(expiry),
                        });
                        postUserBlocksAd(this.user.userid, this.hasAdBlocker);
                    }
                })
                .catch(/* fail silently, as no one needs to know */);
        }
    },

    mounted() {
        this.layoutMounted = true;
    },
    methods: {
        reportAd() {
            this.$refs['xandr-bottom-fixed-ad'].emitReportAd();
        },
        openAdDsaInfo() {
            this.$refs['xandr-bottom-fixed-ad'].openDsaInfo();
        },
        closeAd() {
            this.$refs['xandr-bottom-fixed-ad'].adPremiumClick();
        },
        storeFullScreenCookie(val) {
            storeXandrFullScreenAdViewed(val);
        },
        readAllHeaderNotification() {
            this.$store.dispatch('notifications/setAllAsRead');
        },
    },
};
</script>
<style lang="scss" scoped>
.slide-up-enter-from,
.slide-up-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

.slide-up-enter-active {
    transition: all 0.3s ease;
}

.slide-up-leave-active {
    transition: all 0.5s ease;
}
.bottom-fixed {
    @apply fixed w-full bottom-0 left-0 right-0;
    display: flex;
    justify-content: center;
}
</style>
